/**
 *	landingPage
 */

 export default {
    title: "Politique de confidentialité",
    when_use_data: "Quand et pourquoi recueillons-nous des informations personnelles à votre sujet ?",
    personal_info: "Les informations personnelles vous concernant peuvent être recueillies lorsque vous souscrivez à l'un de nos services.",
    whats_data: "Quelles sortes d'informations recueillons-nous ?",
    your_information: "Informations vous concernant :Il arrive que certaines informations soient obligatoires pour valider votre  compte (c'est le cas pour votre adresse électronique, votre date de naissance et votre prénom) et d'autres seulement optionnelles mais nécessaires si vous désirez recevoir certains produits, services ou participer aux activités ou offres diverses (c'est le cas pour votre numéro de téléphone portable).",
    soumission: "Si vous ne souhaitez pas être contacté par email pour recevoir ces informations, il suffit de vous désinscrire en bas de l'email par un simple clic.",
    not_personal_info: "Informations non personnelles",
    not_personal_info_details: "Certaines informations non personnelles peuvent être recueillies comme, la version de votre navigateur (Internet Explorer, Netscape, etc.), le type de système d'exploitation que vous utilisez (Windows, Mac Os, etc.) et votre nom de domaine (adresse IP) ou votre fournisseur d'accès Internet.",
    who_can_acces_of_datas: "Qui a accès à ces informations ?",
    our_services: "Notre service.",
    datas_information: "Les informations recueillies à votre sujet par notre service sont exclusivement utilisées pour vous offrir différents services. Toutes les précautions ont été prises pour archiver ces informations dans un environnement sécurisé. Seuls certains de nos employés ont accès à ces informations, qui leur sont accessibles uniquement en cas de besoin. Elles ne sont jamais offertes à de tierces personnes, ni vendues ou échangées sans votre consentement.",
    you_can_access: "Vous avez accès à vos informations personnelles sur le site dans la section ' Mon compte '. Vous êtes seul(e) à pouvoir les modifier.",
    what_is_cookie: "Qu'est-ce qu'un cookie et comment est-il utilisé par notre site ?",
    cookie_details: " Un cookie est un fichier, envoyé par un serveur et archivé par votre ordinateur lorsque vous visitez des sites Internet ou utilisez certaines options sur ces sites. Les cookies sont utilisés sur beaucoup de sites Internet pour vous identifier lors de vos visites. Si vous retournez sur un site déjà visité le cookie vous reconnaît.",
    access: "Vous disposez d'un droit d'accès, de modification, de rectification et de suppression des données qui vous concernent (art. 34 de la loi ' Informatique et Libertés ' du 6 janvier 1978). Vous pouvez exercer ce droit par courrier électronique :",
    to_update_info: "Pour modifier vous-même les informations que vous avez fournies sur le site, allez dans la section ' Mon compte'. Pour tout changement, correction ou mise à jour de vos informations personnelles, choisissez les parties à modifier. vous avez également la possibilité de demander la suppression de votre compte."

}
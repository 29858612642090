<template>
  <router-view />
<!--  <script src="https://js.pusher.com/8.2.0/pusher.min.js"></script>-->
</template>

<script>

export default {
  name: 'App',

}
</script>



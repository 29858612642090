/**
*	pageAuthRecoverPassword
*/

export default {
  title: 'Recup\u00E9ration du mot de passe',
  back: 'Retour',
  description: 'Veuillez saisir votre email ci dessous. Nous allons vous envoyer un email',
  form: {
    email: {
      label: 'Adresse E-mail',
      placeholder: 'ex : me@exemple.com'
    },
    submit: 'Envoyer'
  }
}

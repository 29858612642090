/**
 *	pageAuthLogin
 */

export default {
    title: 'Connectez - vous',
    form: {
        email: {
            label: 'Adresse E-mail'
        },
        password: {
            label: 'Mot de passe'
        }
    },
    forgot_password: 'Mot de passe oubli\u00E9 ?',
    no_account: "Vous n'avez pas de compte ?",
    register: 'Cr\u00E9er un compte',
    submit: 'Connexion'
}
import store from '@/store/index.js';
import axios from '@/axios.js';
import auth from '@/auth'

export default async function member({ next, router, to }) {

    if(to.query.autolog){
        await auth.autologin(to.query.autolog)
        router.push(to.path)
    }

  if (!store.state.user) {
    return router.push({ name: `home` });
  }

  return next();
}
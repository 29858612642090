/**
 *	landingPage
 */

 export default {
    header: {
        navigation: {
            home: 'Home',
            testimony: 'Testimonials',
            bio: 'Biography',
            fr: 'FR',
            en: 'EN',
        },
        private_space: 'Login',
        disconnection: 'Disconnexion',
    },
    body: {
        form: {
            yes_free_clairvoyance: 'Yes, I offer you your free clairvoyance',
            your_firstname: 'Your first name',
            your_email: 'Your email',
            your_birthday: 'Your date of birth',
            you_are: 'You are',
            gender_woman: 'A Woman',
            gender_man: 'A Man',
            i_want_my_free_clairvoyance: 'I want my free clairvoyance',
            secure_and_Confidential: '100% Secure and Confidential',
            service_reserved_for_adults: 'Service for adults',
        },
        signature: 'Intuitive clairvoyant for 35 years and expert in Marseille Tarot, guiding with precision and compassion souls in search of their path.',
        my_talents: {
            title: 'My Talents',
            list: {
                text_1: '- Acute perception of energies: Lisa has an exceptional ability to sense and interpret the energies around her, allowing her to deeply understand situations and people without requiring much prior information.',
                text_2: '- Communication with Spirit Guides: She is skilled at establishing connections with spirit guides, receiving clear messages that offer guidance and comfort to those who come to consult her.',
                text_3: '- Expertise in Marseille Tarot: Lisa has mastered the art of Marseille Tarot, using the cards not only to predict the future but also as tools for psychological and spiritual reflection, helping to illuminate choices and life paths.',
                text_4: '- Deep empathy: His great empathy allows him to connect on an emotional level with his interlocutors, offering readings that resonate with their personal experiences and states of mind.',
                text_5: '- Ability to reveal hidden potential: Lisa helps her clients discover their own talents and possibilities, often hidden or overlooked, encouraging them to make informed decisions and realize their full potential.',
            }
        },
        testimonies: 'Testimonials',
        testimonies_data: {
            person_1: {
                bio: 'Melanie',
                description: "In a moment of doubt, Lisa's advice was a beacon in the night. His clairvoyance guided me towards a path of inner peace"
            },
            person_2: {
                bio: 'Maxim',
                description: "I can't thank Lisa enough. His accurate predictions helped me make crucial decisions with confidence."
            },
            person_3: {
                bio: 'Sylvie',
                description: "Lisa has an incredible gift. She was able to detect what I could not see for myself, allowing me to overcome a dark period."
            },
            person_4: {
                bio: 'Stephane',
                description: "Faced with difficult choices, Lisa's insights were invaluable. She knew how to show me the way with kindness and precision."
            },
            person_5: {
                bio: 'Jane',
                description: "Lisa is more than a psychic; she is a true spiritual guide. His words brought me comfort and direction in times when I felt lost."
            },
            person_6: {
                bio: 'Henry',
                description: "The sessions with Lisa were eye-opening. She helped me see my situation clearly and move forward calmly."
            },
            person_7: {
                bio: 'Helene',
                description: "Thank you, Lisa, for your unwavering support. Your wise advice has helped me navigate tumultuous times with hope and courage."
            },
            person_8: {
                bio: 'Marry',
                description: "I feel incredibly lucky to have crossed paths with Lisa. His wisdom and insight opened doors for me that I didn't even know existed."
            },
            person_9: {
                bio: 'Julie',
                description: "Thanks to Lisa, I was able to take control of my life. His empathy and intuition informed me in crucial decisions."
            },
            
        },
    },
    footer: {
        navigation: {
            cgv: 'General Terms and Conditions',
            confidentiality: 'Confidentiality',
            mention: 'Legal notices',
        },
        all_right_reserved: 'All rights reserved.'
    },
    popup_phone: {
        text_give_phone: "Can you please provide me with your mobile phone number in case I need to pass on any important and urgent information to you? ",
        your_number_phone: "Your Phone Number",
        later: "Later",
        validate: "Submit"
    }
}
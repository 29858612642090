/**
 *	landingPage
 */

 export default {
    title: "Mentions Légales",
    contact_us: "NOUS CONTACTER",
    if_you_have_a_question: "Si vous avez des questions sur le service, vous pouvez nous contacter par email à",
    to_contact_us: "Pour nous contacter",
    // address_specifical: "edited by Cruzeiro SA, Bd Georges-Favon 43, Geneva, swiss company registered in Geneva under CHE-292.107.463",
    address_specifical: "est un site edité par la société Cruzeiro SA, Boulevard Georges-Favon 43, Genève, société suisse enregitrée au registre du commerce sous le numéro CHE-292.107.463.",
}
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import store from './store'

/*
** Axios  for API calls
*/
import Auth from './auth.js';
import VueAxios from 'vue-axios'
import instanceAxios from './axios.js'

import CKEditor from '@ckeditor/ckeditor5-vue'


import Pusher from 'pusher-js';

const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    // useTLS: true, // Activer si vous avez activé l'option TLS dans votre compte Pusher
});


//Vue.prototype.auth = Auth;

//import { library } from "@fortawesome/fontawesome-svg-core";
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

//import * as faIcons from "@fortawesome/free-solid-svg-icons";

// let is = Object.values(faIcons).filter(f => {
//      return f.iconName && f.iconName.length > 1
//  })
//
// library.add(...is);

import i18n from './i18n'


createApp(App)
.use(i18n)
.use(store)
.use(router)
.use(CKEditor)
//.provide('auth', 'Auth')

// .directive("observe-visibility", {
//     beforeMount: (el, binding, vnode) => {
//         (vnode).context = binding.instance
//         ObserveVisibility.bind(el, binding, vnode)
//     },
//     updated: ObserveVisibility.update,
//     unmounted: ObserveVisibility.unbind,
// })

// component("font-awesome-icon", FontAwesomeIcon)

.use(VueAxios, instanceAxios)
.provide('auth', Auth)
.provide('pusher', pusher)
.mount('#app')


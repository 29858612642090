import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import axios from '@/axios.js'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

export default createStore({
    plugins: [vuexLocal.plugin],
    modules: {
    },
    state: {
        token: null,
        user: null,
        tchat_open: true,
        website_user: null,
        website: null,
        conversations: [],
        currentConversation: null,
        userTchatmessage : '',
        nbMessageSentsIfNoCredits: 0,
        nbTransactionsSuccessed: 0,
        nbMessagesPending: 0
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user
        },
        SET_USER_CREDITS(state, credits) {
            state.user.website_user.credits = credits
        },
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_TCHAT_OPEN(state, status) {
            state.tchat_open = status
        },
        SET_WEBSITE(state, website) {
            state.website = website
        },
        SET_CONVERSATIONS(state, conversations) {
            state.conversations = conversations
        },
        SET_CURRENT_CONVERSATION(state, currentConversation) {
            state.currentConversation = currentConversation
        },
        SET_NB_TRANSACTIONS_SUCCESSED(state, nbTransactionsSuccessed) {
            state.nbTransactionsSuccessed = nbTransactionsSuccessed
        },
        SET_COUNT_MESSAGES_SENTS_IF_NO_CREDITS(state, restoreMessageSents = true) {
            if (restoreMessageSents) {
                state.nbMessageSentsIfNoCredits = 0
            } else {
                state.nbMessageSentsIfNoCredits++
            }
        },
        SET_MESSAGES_PENDING_COUNT(state, nbMessagesPending) {
            state.nbMessagesPending = nbMessagesPending
        },
        // SET_USER_TCHAT_MESSAGE(state, message) {
        //     state.userTchatmessage = message
        // },

        // TOGGLE_ANIMATOR_ONLINE(state, status) {
        //     state.user.animator.online = !state.user.animator.online
        // },
    },
    actions: {
        // async fetchConversations({commit}){
        //     let response = await axios.get('/conversations')
        //     commit('SET_CONVERSATIONS', response.data.data)
        // },
        async fetchConversations({ commit }) {
            try {
                let response = await axios.get('/conversations');
                commit('SET_CONVERSATIONS', response.data.data);
            } catch (error) {
                console.error('Error fetching conversations:', error);
                // Handle the error as needed
            }
        },


        // async fetchConversationsForAnimator({commit}){
        //     let response = await axios.get('/conversations_for_animator')
        //     commit('SET_CONVERSATIONS', response.data.data)
        // },


        // Old SetConversation
        // async setConversation({commit}, conversationId){

        //     let response = await axios.get('/conversations/' + conversationId)
        //     commit('SET_CURRENT_CONVERSATION', response.data.data)
        // },
        // Nezs setConversation
        async setConversation({ commit }, conversationId) {
            try {
                let response = await axios.get('/conversations/' + conversationId);
                if (response.data && response.data.data) {
                    commit('SET_CURRENT_CONVERSATION', response.data.data);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération de la conversation :', error);
                // Gérer l'erreur en conséquence, par exemple, afficher un message à l'utilisateur
            }
        },

        async setConversationWithMedium({ commit }, medium_id) {

            let response = await axios.get('/conversation_with_medium/' + medium_id)
            commit('SET_CURRENT_CONVERSATION', response.data.data)
        },

        async fetchUser({ commit }, value) {
            let response = await axios.get('/user')
            commit('SET_USER', response.data)
        },

        async setAnimatorOnlineStatus({ state, dispatch }, animator = null) {
            console.log(animator)

            let params = {};
            let refreshUser = false
            if (animator) {
                params.animator_id = animator.id

            } else {
                // personne connectée
                animator = state.user.animator
                refreshUser = true
            }


            if (animator.online === true) {
                // animator.online = false
                await axios.put('/animator/setOffline', params)

            } else {
                // animator.online = true
                await axios.put('/animator/setOnline', params)

                window.location.reload(true);

            }

            dispatch('fetchConversations')


            if (refreshUser) {
                await dispatch('fetchUser')
            }
        }
        // test : async ({state, commit}, value) => {
        // }
    }
})
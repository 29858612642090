import store from '@/store/index.js';
import axios from '@/axios.js';
import auth from '@/auth'

export default async function canSeePv({ next, router, to }) {
  let resp = await axios.get('autorizeToSeePv/'+ store.state.user.id)
  if (!resp.data.data) {
    return router.push({ name: `PaymentPage` });
  }
  return next();
}

/**
*	pageAuthSetNewPassword
*/

export default {
  title: 'Nouveau mot de passe',
  back: 'Retour',
  description: 'Veuillez saisir votre mot de passe personnel',
  form: {
    password: {
      label: 'Nouveau mot de passe'
    },
    password_confirmation: 'Confirmation',
    submit: 'Valider'
  }
}
import store from '@/store/index.js';
import axios from '@/axios.js';
import auth from '@/auth'

export default async function backoffice({ next, router, to }) {

  if (!store.state.user) {
    return router.push({ name: `home` });
  }

  let resp = await axios.get('interfaces/getUser/'+ store.state.user.id)

  if (resp.data.user.email === 'animjoel@cruzeiro.com') {
    console.log('anim joel');
    return next();
  }

  if (store.state.user.admin || store.state.user.animator) {
    return next();
  }

  
  if (!resp.data.user) {
    return router.push({ name: `home` });
  }

  store.commit('SET_USER', resp.data.user)
  
  if (!resp.data.user.admin) {
    return router.push({ name: `home` });
  }


  // if(to.query.autolog){
  //     await auth.autologin(to.query.autolog)
  //     router.push(to.path)
  // }

  

  return next();
}
import { createI18n } from 'vue-i18n'

import fr from '@/langs/fr.js'
import en from '@/langs/en.js'

const messages = {
  fr,
  en,
}

// function loadLocaleMessages() {
//   const locales = require.context('./langs', false, /[A-Za-z0-9-_,\s]+\.js$/i)
//   const messages = {}

//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key).default
//     }
//   })
//   // console.log('messages', messages)
//   return messages
// }

export default createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || 'fr',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  // messages: loadLocaleMessages()
  messages
})
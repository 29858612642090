/**
 ** auth
 */

//import layoutAuth from '@/langs/fr/layout/auth.js'
import pageAuthLogin from '@/langs/en/pages/auth/login.js'
import pageLanding from '@/langs/en/pages/landing.js'
import pageAuthRecoverPassword from '@/langs/en/pages/auth/recover-password.js'
import pageAuthSetNewPassword from '@/langs/en/pages/auth/set-new-password.js'

import pageCgv from '@/langs/en/pages/cgv.js'
import pageConfidentiality from '@/langs/en/pages/confidentialy.js'
import pageMention from '@/langs/en/pages/mention.js'

export default {
  pageAuthLogin,
  pageLanding,
  pageAuthRecoverPassword,
  pageAuthSetNewPassword,
  pageCgv,
  pageConfidentiality,
  pageMention
}
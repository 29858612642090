/**
 *	landingPage
 */

 export default {
    title: "Legal Notices",
    contact_us: "CONTACT US",
    if_you_have_a_question: "If you have questions or if you want to contact us please write us an email at",
    to_contact_us: "To contact us",
    address_specifical: "edited by Cruzeiro SA, Bd Georges-Favon 43, Geneva, swiss company registered in Geneva under CHE-292.107.463",
}
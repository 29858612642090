/**
 *	landingPage
 */

 export default {
    title: "Privacy Policy",
    when_use_data: "When and why do we collect personal information about you ?",
    personal_info: "Personal information about you may be collected when you subscribe to one of our services.",
    whats_data: "What kind of information do we collect ?",
    your_information: "Information about you: Some information may be required to validate your account (this is the case for your email address, date of birth and first name) and other information may be optional but necessary if you wish to receive certain products, services or participate in various activities or offers (this is the case for your mobile phone number.",
    soumission: "If you do not wish to be contacted by email to receive this information, simply unsubscribe at the bottom of the email with a simple click.",
    not_personal_info: "Non-personal information",
    not_personal_info_details: "Some non-personal information may be collected, such as the version of your browser (Internet Explorer, Netscape, etc.), the type of operating system you use (Windows, Mac Os, etc.) and your domain name (IP address) or your Internet service provider.",
    who_can_acces_of_datas: "Who has access to this information ?",
    our_services: "Our service.",
    datas_information: "The information collected about you by our service is used exclusively to offer you various services. All precautions have been taken to archive this information in a secure environment. Only some of our employees have access to this information, which is accessible to them only when necessary. They are never offered to third parties, nor sold or exchanged without your consent.",
    you_can_access: "You have access to your personal information on the site in the 'My account' section. You are the only one who can modify them.",
    what_is_cookie: "What is a cookie and how is it used by our site ?",
    cookie_details: "A cookie is a file, sent by a server and archived by your computer when you visit websites or use certain options on these sites. Cookies are used on many websites to identify you during your visits. If you return to a site already visited, the cookie recognizes you.",
    access: "You have the right to access, modify, rectify and delete data concerning you (art. 34 of the 'Informatique et Libertés' law of January 6, 1978). You can exercise this right by email:",
    to_update_info: "To modify the information you have provided on the site yourself, go to the 'My account' section. For any changes, corrections or updates to your personal information, choose the parts to modify. You also have the option to request deletion of your account."

}
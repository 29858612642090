import axios from 'axios'
//import store from "@/store/index.js"

console.log('****************   TEST  ******************')
console.log('VUE_APP_LARAVEL_ENDPOINT', process.env.VUE_APP_LARAVEL_ENDPOINT)

const instanceAxios = axios.create({
  baseURL: process.env.VUE_APP_LARAVEL_ENDPOINT + '/api',
  timeout: 20000,
});

instanceAxios.defaults.withCredentials = true;

instanceAxios.interceptors.request.use((config) => {
  // Vérifier si des paramètres existent déjà dans la requête

  // Vérifier si des en-têtes existent déjà dans la requête
  config.headers = config.headers || {};

  // Ajouter le paramètre "website: 1" dans l'en-tête // Pas BON, ne pas forcer le website_id

  let hostname = window.location.hostname
  // let hostname_array = hostname.split('.');
  let first_domain = hostname.replace('www.', '');
  if (first_domain == 'localhost' || first_domain == '127.0.0.1') {
    first_domain = process.env.VUE_APP_WEBSITE;
  }

  console.log('first_domain', first_domain);
  // config.headers['website'] = process.env.VUE_APP_WEBSITE;
  config.headers['website'] = first_domain;

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default instanceAxios
/**
 *	landingPage
 */

 export default {
    header: {
        navigation: {
            home: 'Accueil',
            testimony: 'Témoignages',
            bio: 'Biographie',
            fr: 'FR',
            en: 'EN',
        },
        private_space: 'Espace privé',
        disconnection: 'Déconnexion',
    },
    body: {
        form: {
            yes_free_clairvoyance: 'Oui, je vous offre votre voyance gratuite',
            your_firstname: 'Votre Prénom :',
            your_email: 'Votre E-mail :',
            your_birthday: 'Votre Date de Naissance :',
            you_are: 'Vous êtes :',
            gender_woman: 'Une femme',
            gender_man: 'Un homme',
            i_want_my_free_clairvoyance: 'Je veux ma voyance gratuite',
            secure_and_Confidential: '100% Sécurisé et Confidentiel',
            service_reserved_for_adults: 'Service réservé aux personnes majeures',
        },
        signature: 'Voyante intuitive depuis 35 ans et experte en Tarot de Marseille, guidant avec précision et compassion les âmes en quête de leur chemin.',
        my_talents: {
            title: 'Mes Talents',
            list: {
                text_1: '- Perception aiguë des énergies : Lisa possède une capacité exceptionnelle à ressentir et interpréter les énergies qui l\'entourent, lui permettant de comprendre profondément les situations et les personnes sans nécessiter beaucoup d\'informations préalables.',
                text_2: '- Communication avec les guides spirituels : Elle est douée pour établir des liens avec les guides spirituels, recevant des messages clairs qui offrent guidance et réconfort à ceux qui viennent la consulter.',
                text_3: '- Expertise en Tarot de Marseille : Lisa maîtrise l\'art du Tarot de Marseille, utilisant les cartes non seulement pour prédire l\'avenir mais aussi comme outils de réflexion psychologique et spirituelle, aidant à éclairer les choix et les chemins de vie.',
                text_4: '- Empathie profonde : Sa grande empathie lui permet de se connecter à un niveau émotionnel avec ses interlocuteurs, offrant des lectures qui résonnent avec leurs expériences personnelles et leurs états d\'âme.',
                text_5: '- Capacité à révéler le potentiel caché : Lisa aide ses clients à découvrir leurs propres talents et possibilités, souvent cachés ou négligés, les encourageant à prendre des décisions éclairées et à réaliser pleinement leur potentiel.',
            }
        },
        testimonies: 'Témoignages',
        testimonies_data: {
            person_1: {
                bio: 'Mélanie',
                description: "Dans un moment de doute, les conseils de Lisa ont été un phare dans la nuit. Sa clairvoyance m'a guidé vers un chemin de paix intérieure"
            },
            person_2: {
                bio: 'Maxime',
                description: "Je ne saurais assez remercier Lisa. Ses prédictions justes m'ont aidé à prendre des décisions capitales avec assurance."
            },
            person_3: {
                bio: 'Sylvie',
                description: "Lisa a un don incroyable. Elle a su déceler ce que je n'arrivais pas à voir par moi-même, me permettant de surmonter une période sombre."
            },
            person_4: {
                bio: 'Stéphane',
                description: "Face à des choix difficiles, les éclairages de Lisa ont été précieux. Elle a su me montrer la voie avec bienveillance et précision."
            },
            person_5: {
                bio: 'Jeanne',
                description: "Lisa est plus qu'une voyante; elle est une véritable guide spirituelle. Ses paroles m'ont apporté réconfort et direction dans les moments où je me sentais perdu."
            },
            person_6: {
                bio: 'Pierre',
                description: "Les séances avec Lisa ont été révélatrices. Elle m'a aidé à voir clair dans ma situation et à avancer sereinement."
            },
            person_7: {
                bio: 'Hélène',
                description: "Merci, Lisa, pour ton soutien indéfectible. Tes conseils avisés m'ont aidé à traverser des moments tumultueux avec espoir et courage."
            },
            person_8: {
                bio: 'Marie',
                description: "Je me sens incroyablement chanceux d'avoir croisé le chemin de Lisa. Sa sagesse et sa perspicacité m'ont ouvert des portes que je ne savais même pas existantes."
            },
            person_9: {
                bio: 'Julie',
                description: "Grâce à Lisa, j'ai pu reprendre ma vie en main. Son empathie et son intuition m'ont éclairé dans des décisions cruciales."
            },
            
        },
    },
    footer: {
        navigation: {
            cgv: 'CGV',
            confidentiality: 'Confidentialité',
            mention: 'Mentions légales',
        },
        all_right_reserved: 'Tous droits réservés.'
    },
    popup_phone: {
        text_give_phone: "Pouvez-vous s'il vous plaît me communiquer votre numéro de téléphone portable au cas où je devrais vous transmettre des informations importantes et urgentes ? ",
        your_number_phone: "Votre Numéro de téléphone",
        later: " Plus tard",
        validate: "Valider"
    }
}